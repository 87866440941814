const scrollToTopElement = document.getElementById('scroll-to-top');
// hide initially
scrollToTopElement.style.display = "none";
// show when scrolling but hide again when reach top
const onScroll = () => {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    scrollToTopElement.style.display = "block";
  } else {
    scrollToTopElement.style.display = "none";
  }
}

const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

// Needed to trigger scroll function with an eventlistener otherwise wont work in external script
scrollToTopElement.addEventListener('click', () => {scrollToTop()})

window.onscroll = onScroll